/* Style général pour le conteneur */
.loginsignup {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    background-color: #f9f9f9;
}

.loginsignup-container {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* Style pour les titres */
h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 1rem;
    color: #333;
}

/* Style des champs de texte */
.loginsignup-fields input {
    width: 100%;
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

/* Bouton pour continuer */
.continue-button, .google-button {
    width: 100%;
    padding: 0.8rem;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.continue-button {
    background-color: #4CAF50;
    color: white;
}

.google-button {
    background-color: #db4437;
    color: white;
}

/* Texte pour changer d'état (connexion/inscription) */
.loginsignup-login {
    text-align: center;
    font-size: 14px;
    color: #555;
}

.loginsignup-login span {
    color: #007BFF;
    cursor: pointer;
}

.loginsignup-login span:hover {
    text-decoration: underline;
}

/* Message d'erreur */
.error-message {
    color: red;
    font-size: 14px;
    text-align: center;
}

/* Style pour les conditions d'utilisation */
.loginsignup-agree {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.loginsignup-agree p {
    font-size: 14px;
    color: #555;
}

/* Responsive Design */
@media (max-width: 600px) {
    .loginsignup-container {
        padding: 1.5rem;
        max-width: 100%;
    }

    h1 {
        font-size: 20px;
    }

    .loginsignup-fields input {
        font-size: 14px;
    }

    .continue-button, .google-button {
        font-size: 14px;
        padding: 0.7rem;
    }
}
