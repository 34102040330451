/* Général */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f0f0f0;
}

.navbar-logo img {
    height: 50px;
}

.navbar-menu {
    display: flex;
    list-style: none;
    gap: 20px;
    
}

.navbar-menu li {
    padding: 10px;
}

.navbar-menu li a {
    color: #373737;
    text-decoration: none;
    font-weight: 600;
}

.auth-button {
    background-color: #007BFF; /* Couleur du bouton de connexion/déconnexion */
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 20px;
    position: relative;
    bottom: 20%;
}

.auth-button:hover {
    background-color: #0056b3;
}

/* Menu burger - visible sur les petits écrans */
.navbar-toggle {
    display: none;
    cursor: pointer;
    flex-direction: column;
    gap: 5px;
}

.burger-icon {
    width: 25px;
    height: 3px;
    background-color: white;
}

.burger-icon::before,
.burger-icon::after {
    content: '';
    width: 25px;
    height: 3px;
    background-color: white;
    display: block;
    margin-top: 5px;
}

.navbar-menu {
    display: flex;
    list-style: none;
    gap: 20px;
}

.navbar-menu.open {
    display: block;
}

@media (max-width: 768px) {
    .navbar-menu {
        position: absolute;
        top: 60px;
        right: 0;
        width: 100%;
        background-color: #f0f0f0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 60vh;
        display: none;
    }
    

    .navbar-menu.open {
        display: flex;
    }

    .navbar-menu li {
        margin: 20px 0;
    }

    .navbar-toggle {
        display: flex;
    }

    .navbar-logo {
        margin-left: 20px;
    }
}
