.mid{
    text-align: center;
    color: #2C2C2C;
    margin-top: 60px;
}

/* Styles pour la protection par mot de passe */
.password-protection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
    color: #333;
    font-family: Arial, sans-serif;
    text-align: center;
}

.password-protection h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.password-protection input {
    width: 300px;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s;
}

.password-protection input:focus {
    border-color: #007BFF;
}

.password-protection button {
    width: 150px;
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.password-protection button:hover {
    background-color: #0056b3;
}
